import {getFormMessages, isRsvp} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {hasAgreedWithPolicies, getPolicies, shouldShowAlert} from '../../selectors/policies'
import {isPaidTicketSelected} from '../../selectors/tickets'
import {Policies as Presentation} from './policies'
import {PoliciesRuntimeProps, PoliciesOwnProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {toggleAgreePolicies, openPolicyModal},
}: DetailsPageAppProps): PoliciesRuntimeProps => {
  const event = state.event
  const rsvp = isRsvp(event)

  return {
    agreedWithPolicies: hasAgreedWithPolicies(state),
    messages: getFormMessages(event),
    rsvp,
    policies: getPolicies(state),
    showAlert: shouldShowAlert(state),
    paidTicketSelected: isPaidTicketSelected(state.tickets, state.selectedTickets),
    toggleAgreePolicies,
    openPolicyModal,
  }
}

export const Policies = connect<PoliciesOwnProps, PoliciesRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
