import {withTranslation} from 'react-i18next'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {OrderCanceledOwnProps, OrderCanceledRuntimeProps} from './interfaces'
import {OrderCanceled as Presentation} from './order-canceled'

const mapRuntime = ({actions: {navigateToDetails}, state}: DetailsPageAppProps): OrderCanceledRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToDetails,
})

export const OrderCanceled = connect<OrderCanceledOwnProps, OrderCanceledRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)
