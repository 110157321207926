import {withTranslation} from 'react-i18next'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {OrderExpired as Presentation} from './order-expired'
import {OrderExpiredRuntimeProps} from './interfaces'

const mapRuntime = ({actions: {navigateToMainPage}}: DetailsPageAppProps) => ({
  navigateToMainPage,
})

export const OrderExpired = connect<{}, OrderExpiredRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
