import {HEADER_LAYOUT} from '@wix/wix-events-commons-statics'
import {getHeaderLayout} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Header as HeaderPresentation} from './header'

export interface HeaderProps extends HeaderRuntimeProps, HeaderOwnProps {}

export interface HeaderOwnProps {
  onClickRegistrationButton: Function
}

export interface HeaderRuntimeProps {
  headerLayout: HEADER_LAYOUT
}

const mapRuntime = ({state}: DetailsPageAppProps): HeaderRuntimeProps => ({
  headerLayout: getHeaderLayout(state.component.settings),
})

export const Header = connect<HeaderOwnProps, HeaderRuntimeProps>(mapRuntime)(HeaderPresentation)
