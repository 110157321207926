import classNames from 'classnames'
import React from 'react'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import {getFormClassName} from '../../selectors/settings'
import c from '../classnames.scss'
import {FormButton} from '../form-button'
import s from './change-rsvp.scss'
import {ChangeRsvpProps} from './index'

export const ChangeRsvp: React.FC<ChangeRsvpProps> = ({
  buttonStyle,
  rsvpStatusMessageTranslation,
  rsvpStatusButtonTextTranslation,
  t,
  changeMemberRsvp,
  navigateToMainPage,
}) => {
  const handleChangeRsvpClick = async () => changeMemberRsvp()
  const containerClass = classNames(c.formBackgroundColor, getFormClassName(buttonStyle))

  return (
    <div className={containerClass}>
      <div className={s.editRsvpMessages}>
        <div className={classNames(s.editRsvpTitle, c.formTitleFont, c.formTitleColor)}>
          {t(rsvpStatusMessageTranslation)}
        </div>
        <FormButton
          text={t(rsvpStatusButtonTextTranslation)}
          buttonStyle={buttonStyle}
          width="auto"
          onClick={handleChangeRsvpClick}
        />
        <button className={s.back} data-hook="back-to-site" onClick={() => navigateToMainPage()}>
          <Text typography={TYPOGRAPHY.listText}>{t('members.updateRsvp.link')}</Text>
        </button>
      </div>
    </div>
  )
}
