import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import Check from 'wix-ui-icons-common/Check'
import {DH} from '../../../constants/data-hooks'
import c from '../../classnames.scss'
import {StepProps} from './interfaces'
import s from './step.scss'

export const Step: React.FC<StepProps> = ({
  stepKey,
  title,
  index,
  editing,
  completed,
  indexVisible,
  dividerVisible,
  renderStep,
  onEdit,
  t,
}) => {
  const disabled = !editing && !completed
  const collapsed = completed && !editing
  const editAction = () => onEdit(stepKey)

  return (
    <div
      data-hook={DH.CHECKOUT_STEP(stepKey)}
      className={classNames(s.container, {[s.opened]: editing, [s.disabled]: disabled})}
    >
      {dividerVisible && <div className={classNames(s.divider, c.formTitleColor)} />}
      <div className={s.content}>
        <div className={classNames(s.row, s.titleContainer, c.formTitleFont, c.formTitleColor)}>
          <div data-hook={DH.CHECKOUT_STEP_TITLE} className={classNames(s.title, s.row)}>
            {(indexVisible || collapsed) && (
              <div className={s.titleLeft}>{collapsed ? <Check size="1.5em" className={s.check} /> : `${index}.`}</div>
            )}
            <div>{title}</div>
          </div>
          {collapsed && (
            <div
              data-hook={DH.CHECKOUT_STEP_EDIT}
              className={s.edit}
              onClick={editAction}
              onKeyDown={handleKeyPressEnterOrSpace(editAction)}
              tabIndex={0}
            >
              {t('mobile.checkout.edit')}
            </div>
          )}
        </div>
        {!disabled && renderStep(editing)}
      </div>
    </div>
  )
}
