import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import fs from '../../fields.scss'
import c from '../../../classnames.scss'
import s from './date.scss'
import {DateProps, DateState} from '.'

export class Date extends React.Component<DateProps, DateState> {
  year: string
  month: string
  day: string

  state = {touched: {year: false, month: false, day: false}}

  constructor(props: DateProps) {
    super(props)
    this.setInitialData()
  }

  setInitialData() {
    const {value} = this.props.input
    this.year = value.split('-')[0]
    this.month = value.split('-')[1]
    this.day = value.split('-')[2]
  }

  onChange(key: string, value: string) {
    this[key] = value
    const date = this.formatDate()
    this.props.input.onChange(date)
  }

  formatDate() {
    if (!this.year && !this.month && !this.day) {
      return null
    } else {
      return `${this.year}-${this.padDateComponentWithZero(this.month)}-${this.padDateComponentWithZero(this.day)}`
    }
  }

  padDateComponentWithZero(value: string = '') {
    return value.length === 1 ? `0${value}` : value
  }

  setTouched(key: string) {
    const {touched} = this.state
    if (!touched[key]) {
      touched[key] = true
      this.setState({touched})
    }
  }

  isAllTouched() {
    const {touched} = this.state
    return Object.keys(touched).every(key => touched[key]) || this.props.meta.touched
  }

  isInvalid() {
    return this.isAllTouched() && this.props.meta.error
  }

  render() {
    const {
      meta: {error},
      control,
      t,
    } = this.props
    const {mandatory, label} = getInputControlMeta(control)
    const invalid = this.isInvalid()

    return (
      <div data-hook={invalid ? DH.FORM_INVALID_FIELD : ''} className={fs.field}>
        <Label classes={fs.customCaption} dataHook={DH.FORM_DATE_LABEL} required={mandatory} caption={label} />
        <div className={s.fields}>
          <input
            className={classNames(c.formInputBorderColor, fs.input, s.month, {[c.formInputError]: invalid})}
            data-hook={DH.FORM_DATE_INPUT_MONTH}
            type="tel"
            value={this.month}
            pattern="[0-9]*"
            maxLength={2}
            required={mandatory}
            placeholder="MM"
            aria-label={`${label}: ${t('accessibility:a11y.month')}`}
            onBlur={() => this.setTouched('month')}
            onChange={event => this.onChange('month', event.target.value)}
          />
          <input
            className={classNames(c.formInputBorderColor, fs.input, s.day, {[c.formInputError]: invalid})}
            data-hook={DH.FORM_DATE_INPUT_DAY}
            type="tel"
            value={this.day}
            pattern="[0-9]*"
            maxLength={2}
            required={mandatory}
            placeholder="DD"
            aria-label={`${label}: ${t('accessibility:a11y.day')}`}
            onBlur={() => this.setTouched('day')}
            onChange={event => this.onChange('day', event.target.value)}
          />
          <input
            className={classNames(c.formInputBorderColor, fs.input, s.year, {[c.formInputError]: invalid})}
            data-hook={DH.FORM_DATE_INPUT_YEAR}
            type="tel"
            value={this.year}
            pattern="[0-9]*"
            maxLength={4}
            required={mandatory}
            placeholder="YYYY"
            aria-label={`${label}: ${t('accessibility:a11y.year')}`}
            onBlur={() => this.setTouched('year')}
            onChange={event => this.onChange('year', event.target.value)}
          />
        </div>
        <Error dataHook={DH.FORM_DATE_ERROR} error={invalid && t(error)} />
      </div>
    )
  }
}
