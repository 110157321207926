import {getEventDescription} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {isDescriptionVisible} from '../../../../selectors/event'
import {connect} from '../../../runtime-context/connect'
import {Description as DescriptionPresentation} from './description'

export interface DescriptionOwnProps {}
export interface DescriptionRuntimeProps {
  description: string
  descriptionVisible: boolean
}
export interface DescriptionProps extends DescriptionOwnProps, DescriptionRuntimeProps {}

const mapRuntime = ({state}: DetailsPageAppProps): DescriptionRuntimeProps => ({
  description: getEventDescription(state.event),
  descriptionVisible: isDescriptionVisible(state),
})

export const Description = connect<DescriptionOwnProps, DescriptionRuntimeProps>(mapRuntime)(DescriptionPresentation)
