import {isCheckoutButtonDisabled} from '../../../../selectors/event'
import {getButtonStyle} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {Footer as Presentation} from './footer'
import {FooterOwnProps, FooterRuntimeProps} from './interfaces'

export const mapRuntime = ({state, actions: {checkout}}: DetailsPageAppProps): FooterRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  checkoutButtonDisabled: isCheckoutButtonDisabled(state),
  checkout,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
