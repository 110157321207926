import {getFormMessages, isRegistrationClosed, isTicketed} from '@wix/wix-events-commons-statics'
import {withTranslation} from 'react-i18next'
import {isMemberEventRsvpReady, memberRsvpExists} from '../../selectors/member-rsvp'
import {shouldNavigateToForm} from '../../selectors/rsvp'
import {getFormButtonStyle} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RsvpFormOwnProps, RsvpFormRuntimeProps} from './interfaces'
import {RsvpForm as Presentation} from './rsvp-form'

const mapRuntimeToProps = ({
  state,
  actions: {formPageLoaded, navigateToChangeRsvp, navigateToMainPage},
}: DetailsPageAppProps): RsvpFormRuntimeProps => {
  const event = state.event

  return {
    closedRegistrationMessage: getFormMessages(event).registrationClosed,
    registrationClosed: isRegistrationClosed(event),
    buttonStyle: getFormButtonStyle(state.component.settings),
    memberRsvpExists: memberRsvpExists(state),
    memberRsvpReady: isMemberEventRsvpReady(state),
    ticketedEvent: isTicketed(event),
    shouldNavigateToForm: shouldNavigateToForm(state),
    formPageLoaded,
    navigateToChangeRsvp,
    navigateToMainPage,
  }
}

export const RsvpForm = connect<RsvpFormOwnProps, RsvpFormRuntimeProps>(mapRuntimeToProps)(
  withTranslation()(Presentation),
)
