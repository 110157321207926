import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {isHollowButton, isRoundedButton} from '../../selectors/settings'
import c from '../classnames.scss'
import s from './form-button.scss'
import {FormButtonProps} from './interfaces'

const getButtonClasses = (buttonStyle: EventDetailsButtonStyle, disabled: boolean, mobile: boolean) => {
  const classes = [s.button, isRoundedButton(buttonStyle) ? c.formButtonRoundedBorderRadius : c.formButtonBorderRadius]

  if (mobile) {
    classes.push(s.mobileButton)
  }

  if (isHollowButton(buttonStyle)) {
    classes.push(c.formHollowButtonFont, c.formHollowButtonColor, c.formButtonBorder)
  } else {
    classes.push(c.formFullButtonFont, c.formFullButtonColor, c.formButtonBackgroundColor)
  }

  if (disabled) {
    classes.push(s.disabled)
  }

  return classes.join(' ')
}

export const FormButton: React.FC<FormButtonProps> = ({
  mobile,
  text,
  buttonStyle,
  type = 'button',
  dataHook,
  width = '100%',
  disabled,
  onClick,
}) => (
  <button
    data-hook={dataHook}
    className={getButtonClasses(buttonStyle, disabled, mobile)}
    style={{width}}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {text}
  </button>
)
