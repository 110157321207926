import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {DH} from '../../../constants/data-hooks'
import {isHollowButton, isRoundedButton} from '../../../selectors/settings'
import cl from '../../classnames.scss'
import s from './rsvp-info-button.scss'
import {RsvpInfoButtonProps} from '.'

const getContainerClasses = (buttonStyle: EventDetailsButtonStyle, mobile: boolean) => {
  const fontClass = isHollowButton(buttonStyle) ? cl.rsvpHollowButtonFont : cl.rsvpButtonFont
  const colorClass = isHollowButton(buttonStyle) ? cl.rsvpHollowButtonColor : cl.rsvpButtonColor
  const backgroundClass = isHollowButton(buttonStyle) ? '' : cl.rsvpButtonBackgroundColor
  const borderClass = isHollowButton(buttonStyle) ? cl.rsvpButtonBorder : ''
  const radiusClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedBorderRadius : cl.rsvpButtonBorderRadius

  const classes = [fontClass, colorClass, backgroundClass, borderClass, radiusClass, s.container]

  if (mobile) {
    classes.push(s.mobileContainer)
  }

  return classes.join(' ')
}

export const RsvpInfoButton: React.FC<RsvpInfoButtonProps> = ({messages, dataHook, onClick, buttonStyle, mobile}) => (
  <div className={getContainerClasses(buttonStyle, mobile)} data-hook={dataHook}>
    <div>{messages.message}</div>
    <button className={s.link} onClick={() => onClick()} data-hook={DH.RSVP_INFO_BUTTON}>
      {messages.exploreEventsActionLabel}
    </button>
  </div>
)
