import {isMobile} from '../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {FormButton as Presentation} from './form-button'
import {FormButtonOwnProps, FormButtonRuntimeProps} from './interfaces'

const mapRuntimeToProps = ({state}: DetailsPageAppProps): FormButtonRuntimeProps => ({
  mobile: isMobile(state),
})

export const FormButton = connect<FormButtonOwnProps, FormButtonRuntimeProps>(mapRuntimeToProps)(Presentation)
