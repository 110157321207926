import {handleSpaceKeyDown} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import c from '../../../classnames.scss'
import {DH} from '../../../../constants/data-hooks'
import s from './checkbox.scss'
import {CheckboxProps} from '.'

export class Checkbox extends React.Component<CheckboxProps> {
  onChange = () => this.props.onChange(this.props.value)

  render() {
    const {value, checked, label} = this.props

    return (
      <div
        tabIndex={0}
        role="checkbox"
        aria-checked={checked}
        onClick={this.onChange}
        onKeyDown={handleSpaceKeyDown(this.onChange)}
      >
        <input
          className={s.input}
          data-hook={DH.CHECKBOX_INPUT}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={this.onChange}
        />
        <label className={s.wrapper}>
          <span className={classNames(c.formBorderColor, s.checkbox)} data-hook={DH.CHECKBOX} />
          <div className={classNames(s.label, c.formLabelsFont, c.formLabelsColor)} data-hook={DH.CHECKBOX_LABEL}>
            {label || value}
          </div>
        </label>
      </div>
    )
  }
}
