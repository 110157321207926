import {withTranslation} from 'react-i18next'
import {resolveButtonTextTranslation, resolveStatusTextTranslation} from '../../../selectors/member-rsvp'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {EditRegistrationButton as Presentation} from './edit-registration-button'
import {EditRegistrationButtonRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: DetailsPageAppProps): EditRegistrationButtonRuntimeProps => ({
  messageTranslation: resolveStatusTextTranslation(state),
  buttonTextTranslation: resolveButtonTextTranslation(state),
  changeMemberRsvp: actions.changeMemberRsvp,
})

export const EditRegistrationButton = connect<{}, EditRegistrationButtonRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)
export * from './interfaces'
