import {withTranslation} from 'react-i18next'
import {getButtonStyle, isHollowButton} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {ShowDetailsToggleOwnProps, ShowDetailsToggleRuntimeProps} from './interfaces'
import {ShowDetailsToggle as Presentation} from './show-details-toggle'
import s from './show-details-toggle.scss'

const mapRuntime = ({state}: DetailsPageAppProps): ShowDetailsToggleRuntimeProps => ({
  mainActionColorClass: isHollowButton(getButtonStyle(state.component.settings))
    ? s.colorFromHollowButton
    : s.colorFromFullButton,
})

export const ShowDetailsToggle = withTranslation()(
  connect<ShowDetailsToggleOwnProps, ShowDetailsToggleRuntimeProps>(mapRuntime)(Presentation),
)
