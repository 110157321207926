import {isEditor} from '../../../../../../commons/selectors/environment'
import {getImageOpacity, getImagePosition} from '../../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {Image as Presentation} from './image'
import {ImageRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): ImageRuntimeProps => {
  const {event, component} = state
  const {settings} = component

  return {
    mainImage: event.mainImage,
    imagePosition: getImagePosition(settings),
    imageOpacity: getImageOpacity(settings),
    crop: !isEditor(state),
  }
}

export const Image = connect<{}, ImageRuntimeProps>(mapRuntime)(Presentation)
