import classNames from 'classnames'
import React from 'react'
import {getImageStyle} from '../../../../../../commons/utils/image'
import {DH} from '../../../../../constants/data-hooks'
import s from './image.scss'
import {ImageProps} from './interfaces'

const imageSize = 563.5
export const Image: React.FC<ImageProps> = ({mainImage, imagePosition, imageOpacity, crop}) => {
  const container = crop
    ? {height: imageSize, width: imageSize}
    : {height: mainImage.height || imageSize, width: mainImage.width || imageSize}
  return (
    <div className={classNames(s.imageContainer)}>
      <div className={s.imageBackground} />
      <div
        className={s.image}
        data-hook={DH.eventImage}
        style={getImageStyle({
          image: mainImage,
          container,
          opacity: imageOpacity,
          position: imagePosition,
          superUpscaled: true,
        })}
      />
    </div>
  )
}
