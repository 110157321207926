import {CouponErrorCode} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {DH} from '../../../constants/data-hooks'
import c from '../../classnames.scss'
import s from './coupon-error.scss'
import {CouponErrorProps} from '.'

export const CouponError: React.FC<CouponErrorProps> = ({error, t}) => {
  if (!error) {
    return null
  }

  let errorString

  switch (error) {
    case CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST:
      errorString = t('summary.error.couponDoesNotExist')
      break
    case CouponErrorCode.ERROR_COUPON_HAS_EXPIRED:
      errorString = t('summary.error.promotionEnded')
      break
    case CouponErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET:
      errorString = t('summary.error.couponNotActiveYet')
      break
    case CouponErrorCode.ERROR_COUPON_USAGE_EXCEEDED:
      errorString = t('summary.error.couponUsageExceeded')
      break
    case CouponErrorCode.CUSTOM_ERROR_TOO_BUSY:
      errorString = t('summary.error.serverBusy')
      break
    default:
      errorString = t('summary.error.unknown')
  }

  return (
    <div data-hook={DH.COUPON_ERROR} className={classNames(c.formSummaryBody, s.message)}>
      {errorString}
    </div>
  )
}
