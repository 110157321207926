import {getEventId} from '@wix/wix-events-commons-statics'
import {withTranslation} from 'react-i18next'
import {connect} from '../../runtime-context/connect'
import {isOrderCompleted} from '../../../selectors/placed-order'
import {getReservationId} from '../../../selectors/reservation'
import {getSortedSelectedTickets} from '../../../selectors/selected-tickets'
import {isPaidTicketSelected} from '../../../selectors/tickets'
import {
  getPurchasedPlans,
  getSelectedPaidPlan,
  hasPurchasedPlans,
  showPaidPlansPicker,
} from '../../../selectors/paid-plans'
import {DetailsPageAppProps} from '../../app/interfaces'
import {isMobile} from '../../../../commons/selectors/environment'
import {MembershipSelectorOwnProps, MembershipSelectorRuntimeProps} from './interfaces'
import {MembershipSelector as Presentation} from './membership-selector'

const mapRuntime = ({state, actions: {applyPlan}}: DetailsPageAppProps): MembershipSelectorRuntimeProps => ({
  isMobile: isMobile(state),
  eventId: getEventId(state.event),
  reservationId: getReservationId(state),
  purchasedPlans: getPurchasedPlans(state),
  selectedPlan: getSelectedPaidPlan(state),
  orderCompleted: isOrderCompleted(state.placedOrder.order),
  shouldShow:
    hasPurchasedPlans(state) &&
    showPaidPlansPicker(state) &&
    isPaidTicketSelected(state.tickets, getSortedSelectedTickets(state)),
  applyPlan,
})

export const MembershipSelector = withTranslation()(
  connect<MembershipSelectorOwnProps, MembershipSelectorRuntimeProps>(mapRuntime)(Presentation),
)
