import React from 'react'
import {ModalsProps} from './interfaces'
import {OrderSummary} from './order-summary'

export const Modals: React.FC<ModalsProps> = ({modalType}) => {
  switch (modalType) {
    case 'order-summary':
      return <OrderSummary />
    default:
      return null
  }
}
