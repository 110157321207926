import classNames from 'classnames'
import React from 'react'
import c from '../../classnames.scss'
import {DH} from '../../../constants/data-hooks'
import {ReservationTimerProps, ReservationTimerState} from './interfaces'
import s from './reservation-timer.scss'

const ONE_MINUTE = 1000 * 60

export class ReservationTimer extends React.Component<ReservationTimerProps, ReservationTimerState> {
  timerId: NodeJS.Timer
  timer: HTMLElement
  lastTimeAnnounced = ONE_MINUTE * 21

  state = {timeLeft: ''}

  componentDidMount() {
    this.startInterval()
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  announceTimeLeft(timeLeft) {
    if (this.lastTimeAnnounced - timeLeft > ONE_MINUTE && this.timer) {
      this.lastTimeAnnounced = timeLeft
      this.timer.setAttribute('aria-live', 'polite')

      setTimeout(() => {
        this.timer.removeAttribute('aria-live')
      }, 800)
    }
  }

  startInterval() {
    this.timerId = setInterval(() => {
      const expireDate = new Date(this.props.expires).valueOf()
      const now = new Date().valueOf()
      const diffInMilliseconds = expireDate - now

      if (diffInMilliseconds < 1000) {
        this.props.onExpire()
        clearInterval(this.timerId)
      } else {
        const diff = formatDurationUnderHour(diffInMilliseconds)
        this.setState({timeLeft: diff}, () => this.announceTimeLeft(diffInMilliseconds))
      }
    }, 1000)
  }

  setTimerRef = (element: HTMLElement) => (this.timer = element)

  render() {
    const {expires, t, isMobile} = this.props
    const {timeLeft} = this.state

    return expires ? (
      <div
        className={classNames(s.container, c.formSummaryBox, {[s.mobile]: isMobile})}
        data-hook={DH.RESERVATION_TIMER}
      >
        <span aria-hidden={true} className={classNames(s.text, c.formSummaryColor, c.formSummaryBody)}>
          {t('reservation.timer', {time: timeLeft})}
        </span>
        <span className={s.srOnly} ref={this.setTimerRef}>
          {t('a11y.timeRemainingWithTime', {time: timeLeft})}
        </span>
      </div>
    ) : null
  }
}

const formatDurationUnderHour = (diffInMilliseconds: number) => {
  const minute = Math.floor(diffInMilliseconds / ONE_MINUTE)
    .toString(10)
    .padStart(2, '0')
  const seconds = Math.floor((diffInMilliseconds % ONE_MINUTE) / 1000)
    .toString(10)
    .padStart(2, '0')

  return `${minute}:${seconds}`
}
