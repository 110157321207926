import React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import classNames from 'classnames'
import {DH} from '../../../constants/data-hooks'
import {classes} from './schedule-button.st.css'
import {ScheduleButtonProps} from '.'

export const ScheduleButton: React.FC<ScheduleButtonProps> = ({hollowButton, onClick, t, mobile, roundedButton}) => (
  <Button
    upgrade
    priority={PRIORITY.basicSecondary}
    className={classNames(classes.root, hollowButton ? classes.checkoutButtonHollow : classes.checkoutButtonFull, {
      [classes.mobile]: mobile,
      [classes.rounded]: roundedButton,
    })}
    onClick={onClick}
    data-hook={DH.SCHEDULE_SEE_ALL}
  >
    {t('schedule_seeAll')}
  </Button>
)
