import {withTranslation} from 'react-i18next'
import {getEventId, getSlug} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/connect'
import {getInvoice} from '../../../selectors/placed-order'
import {getReservationExpiration, getReservationId} from '../../../selectors/reservation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getFormButtonStyle} from '../../../selectors/settings'
import {isMobile} from '../../../../commons/selectors/environment'
import {MobileCheckoutRuntimeProps} from './interfaces'
import {MobileCheckout as Presentation} from './mobile-checkout'

const mapRuntime = ({
  state,
  actions: {formPageLoaded, cancelReservation, openTicketsDetailsModal, openTimeExpiredModal},
}: DetailsPageAppProps): MobileCheckoutRuntimeProps => {
  const event = state.event
  return {
    eventId: getEventId(event),
    slug: getSlug(event),
    buttonStyle: getFormButtonStyle(state.component.settings),
    reservationId: getReservationId(state),
    reservationExpiration: getReservationExpiration(state),
    invoice: getInvoice(state),
    isMobile: isMobile(state),
    formPageLoaded,
    cancelReservation,
    openTicketsDetailsModal,
    openTimeExpiredModal,
  }
}

export const MobileCheckout = connect<{}, MobileCheckoutRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
