import {isMobile} from '../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SubtitleOwnProps, SubtitleRuntimeProps} from './interfaces'
import {Subtitle as SubtitlePresentation} from './subtitle'

export const mapRuntime = ({state}: DetailsPageAppProps): SubtitleRuntimeProps => ({
  mobile: isMobile(state),
})

export const Subtitle = connect<SubtitleOwnProps, SubtitleRuntimeProps>(mapRuntime)(SubtitlePresentation)
export * from './interfaces'
