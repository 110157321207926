import {getTaxConfig} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {TaxOwnProps, TaxRuntimeProps} from './interfaces'
import {Tax as Presentation} from './tax'

export const mapRuntime = ({state: {event}}: DetailsPageAppProps, {ticket}: TaxOwnProps): TaxRuntimeProps => ({
  visible: Boolean(getTaxConfig(event)) && !ticket.free,
  taxConfig: getTaxConfig(event),
})

export const Tax = connect<TaxOwnProps, TaxRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
